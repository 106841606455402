import Modal from 'react-bootstrap/Modal';
import jobType from "./../assets/Icons/jobType.svg";
import JobTypeIcon from "./../assets/Icons/job-type.svg";
import financialServices from "./../assets/Icons/financialServices.svg";
import alumni from "./../assets/Icons/alumni.svg";
import applicants from "./../assets/Icons/applicants.svg";
import skills from "./../assets/Icons/skills.svg";
import ShareIcon from "./../assets/Icons/share-white.svg";
import parse from "html-react-parser";
import { useState } from 'react';

const tempDetailsList = [
  {
    id: 0,
    name: "Job Description",
    selected: true,
  },
  {
    id: 1,
    name: "Requirements",
    selected: false,
  },
  {
    id: 2,
    name: "Organisation Details",
    selected: false,
  }
];

function JobModal(props) {
  const { selectedJob, handleApplyJobForm, jobApplied, handleShare } = props;
  const [detailsList, setDetailsList] = useState(tempDetailsList);
  console.log(selectedJob)
  const date = new Date(selectedJob?.createdDate);
  const day = date.getDate();

  const handleCategoryChange = (id) => {
    let tempjob = detailsList.map((ev) => {
      if (ev.id === id) {
        return { ...ev, selected: true };
      } else {
        return { ...ev, selected: false };
      }
    });
    setDetailsList(tempjob);
  };

  if (!selectedJob) return <></>
  return (
    <div style={{ zIndex: '5000' }} className='position-fixed py-4 top-24 bottom-0 bg-white left-0 right-0 overflow-y-scroll'>
      <div>
        <h2 className='fw-bold pl-4'>Career opportunity for {selectedJob.designation} at {selectedJob.location}</h2>
        <div className='flex items-center'>
          <img src={selectedJob.jobCategoryImageUrl} alt="" />
          <div className='mt-4'>
            <p>{selectedJob.companyName}</p>
            <p>{selectedJob.location}</p>
          </div>
        </div>
        <div className="text-gray-500 pl-4">
          <p className='pl-4'>Posted: {day} day{day > 1 && 's'} ago</p>
        </div>
        <div className="text-gray-500 px-4">
          <div className="flex my-4">
            <img src={jobType} alt="" />
            <p className="pl-3 m-0">{selectedJob?.jobType}</p>
          </div>
          <div className="flex my-4">
            <img src={JobTypeIcon} alt="" />
            <p className="pl-3 m-0"> {selectedJob?.jobCategory} </p>
          </div>

          <div className="flex my-4">
            <span className="inline-block w-[18px] ml-1">
              {" "}
              &#x20b9;{" "}
            </span>
            <p className="pl-3 m-0">{selectedJob?.salaryText}</p>
          </div>
        </div>
        <div className="flex items-center all-events-categories border-bottom">
          {detailsList.map((event) => {
            return (
              <div
                key={event.id}
                className={`fs-5 p-0 pb-2 category-item ${event.selected ? "selected font-bold" : "font-semibold"
                  }`}
                onClick={() => handleCategoryChange(event.id)}
              >
                <p>
                  {event.name}
                </p>
              </div>
            );
          })}
        </div>
        <div className='p-3 position-fixed bottom-0 left-0 right-0 bg-gray-50'>
          {
            jobApplied ?
              <button className="btn w-75 p-4 fs-3 rounded-lg text-white" style={{ background: '#0057A8' }}>
                Already Applied
              </button> :
              <button onClick={() => handleApplyJobForm(selectedJob?.jobId)} className="btn w-75 p-4 fs-3 rounded-lg fw-lighter text-white" style={{ background: '#0057A8' }}>
                Apply Now
              </button>
          }
          <button onClick={(e) => { e.stopPropagation(); handleShare(selectedJob.jobId) }} className="w-24 p-4 ml-5 rounded-lg btn" style={{ background: '#0057A8' }}>
            <img className='text-white' src={ShareIcon} alt="" />
          </button>
        </div>
        <div className='p-4'>
          {
            detailsList[0].selected &&
            <div>
              {
                selectedJob?.jobDescription && parse(selectedJob.jobDescription)
              }
            </div>
          }
          {
            detailsList[1].selected &&
            <div>
              {
                selectedJob?.requirement && parse(selectedJob.requirement)
              }
            </div>
          }
          {
            detailsList[2].selected &&
            <div>
              {
                selectedJob?.companyDetails && parse(selectedJob.companyDetails)
              }
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default JobModal;
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import '../../../assets/dashboard/dashboard.css'
import UserService from "../../../services/user.service";
import LocationImg from "../../../assets/Icons/location.svg";
import { setLoader, clearLoader } from "../../../store/actions/loader";
import "bootstrap/dist/css/bootstrap.min.css";
import EventDetailModal from "../../../helpers/eventDetailModal";
import RegisteredModal from "../../../helpers/registeredModal";
import { useInterval } from "../../../helpers/useInterval";
import { POLLING_INTERVAL } from "../../../constants/variables";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import userService from "../../../services/user.service";
import ApplicantModal from "../../../helpers/applicantModal";
const HorizontalLine = () => {
  return (
    <div
      style={{
        boxShadow: "0 0 25px 0 rgba(201,208,230,0.38)",
      }}
    >
      <hr className=" border-4 border-top "></hr>
    </div>
  );
};

const Info = (props) => {
  const { reason } = props;
  return (
    <OverlayTrigger
      delay={{ hide: 450, show: 300 }}
      overlay={(props) => <Tooltip {...props}>{reason}</Tooltip>}
      placement="top"
    >
      <i className="fa fa-info-circle" />
    </OverlayTrigger>
  );
};

const MyEvents = (props) => {
  const dispatch = useDispatch();
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [registrations, setRegistrations] = useState([]);
  const [modalShowregistration, setModalShowregistration] = useState(false);
  const [modalShowApplicant, setModalShowApplicant] = useState(false);

  // console.log('events', events)

  const [filterValue, setFilterValue] = useState("All");
  const handleFilter = (e) => {
    setFilterValue(e.target.value);
  };

  useInterval(async () => {
    dispatch(setLoader());
    await UserService.fetchAllEvents().then((res) => {
      dispatch(clearLoader());
      setEvents(res.data.eventBeans);
    });
  }, POLLING_INTERVAL);

  useEffect(() => {
    dispatch(setLoader());
    UserService.fetchAllEvents().then((res) => {
      dispatch(clearLoader());
      setEvents(res.data.eventBeans);
      setSelectedEvent(res.data.eventBeans[0]);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log(events);
  }, [events]);

  const getCsvData = (details) => {
    console.log('details', details);
  }
  // console.log('selectedEvent', selectedEvent);

  return (
    <div className="row p-2 user-dashboard-events-row" style={{ backgroundColor: "#F9F9FB" }}>
      {events.length !== 0 && (
        <>
          <div className="col-12 col-sm-6">
            {/* <select className="form-select" onChange={handleFilter}>
                <option value="All" label="All" />
                <option value="In Review" label="In Review" />
                <option value="Live" label="Live" />
                <option value="Cancelled" label="Cancelled" />
              </select> */}

            <select className="form-select" onChange={handleFilter}>
              <option value="All" label="All" />
              <option
                value="Workshops & Trainings"
                label="Workshops & Trainings"
              />
              <option
                value="Awards & Competitions"
                label="Awards & Competitions"
              />
              <option
                value="Exhibitions & Summits"
                label="Exhibitions & Summits"
              />
            </select>
            {events
              .filter((event) => {
                if (event.eventCategoryText === filterValue) {
                  return event;
                }
                if (filterValue === "All") {
                  return event;
                }
                return null;
              })
              .map((event, index) => {
                const registrationsCount = event.eventRegistrationBeans?.length
                let paidCount = 0
                let unpaidCount = 0
                if (event.fees !== 0) {
                  event.eventRegistrationBeans?.map(item => {
                    if (item.paymentDone) {
                      paidCount += 1
                    } else {
                      unpaidCount += 1
                    }
                  })
                }
                return (
                  <div
                    className="custom-card dashboard-event-card mt-3 p-2 list-group-item list-group-item-action"
                    key={index}
                    onClick={(e) => {
                      setSelectedEvent(event);
                    }}
                  >
                    <div className="d-flex mt-2 active-job">
                      <div className="me-auto w-50 ps-2">
                        <div className="text-break">
                          <span style={{ fontSize: "0.9rem" }}>
                            <strong>{event.title}</strong>
                          </span>
                        </div>
                        <div className="text-muted">
                          <span style={{ fontSize: "0.8rem" }}>
                            {event.postedBy}
                          </span>
                        </div>
                      </div>
                      <div className="me-3 ">
                        {" "}
                        <span
                          className={
                            event.eventCategoryText === "Workshops & Trainings"
                              ? "in-review p-2 inline-block"
                              : event.eventCategoryText ===
                                "Awards & Competitions"
                                ? "live p-2"
                                : "expire-text-box p-2"
                          }
                        >
                          {event.eventCategoryText}
                        </span>
                      </div>
                      <div className="">
                        <span className="expire-text text-center p-2 inline-block">
                          {event.daysToExpiry} days left to register
                        </span>
                      </div>
                    </div>
                    <div className="flex items-start">
                      <div>
                        <div className="mt-2 p-1">
                          <span className="job-type p-2">
                            {event.eventCategoryText}
                          </span>
                          <span className="ms-2 p-2 text-muted">{event.fees}</span>
                          <span className="ms-2 p-2 text-muted">
                            <img
                              src={LocationImg}
                              className="me-1 inline"
                              alt="Location svg"
                            />
                            {event.venue}
                          </span>

                          <span className="ms-2 p-2 text-muted">
                            {registrations.length}
                          </span>

                          {/* <span className="ms-2 p-2 text-muted">
                        {registrations}
                      </span> */}
                        </div>
                        <div className="mt-2">
                          <button
                            className="btn btn-primary d-inline d-sm-none rounded-pill btn-sm"
                            onClick={(e) => {
                              setSelectedEvent(event);
                              setModalShow(true);
                            }}
                          >
                            View event details
                          </button>
                          {
                            event.eventRegistrationBeans.length > 0 ? (
                              <>
                                <p className="mb-0 ml-2 opacity-70">
                                  {event.fees === 0 ? <>
                                    {registrationsCount} Registrations
                                  </> :
                                    <>
                                      {registrationsCount} Registrations {paidCount} Paid and {unpaidCount} unpaid
                                    </>}
                                </p>
                                <button
                                  className="btn btn-danger ms-1 lg-ms-3 rounded-pill btn-sm"
                                  onClick={() => {
                                    setSelectedEvent(event);
                                    setModalShowApplicant(true);
                                  }}
                                >
                                  View Registration Details
                                </button>
                              </>
                            ) : (
                              <button className="btn btn-danger ms-1 lg-ms-3 rounded-pill btn-sm" disabled>
                                No Registrations
                              </button>
                            )
                          }
                        </div>
                      </div>

                      <div className="rounded-xl inline-block ml-2 py-2 px-4 font-weight-bold gap-2 mt-1" style={{ backgroundColor: '#01B0F1', color: '#EEFDFF' }} >
                        <div className="flex items-center mb-2">
                          <h6 className="mb-0"> Registrations Summary </h6>
                          <span className="rounded-pill px-6 py-0 ml-1" style={{ backgroundColor: 'rgb(57 70 131 / 78%)' }} >
                            {
                              event.fees === 0 ? 'NA' : `₹${event.fees * paidCount}`
                            }
                          </span>
                        </div>
                        <div className="flex justify-center items-center">
                          {
                            event.fees === 0 ?
                              <div className="flex flex-col rounded-xl justify-center items-center py-1 px-3 flex-1 mr-2"
                                style={{ backgroundColor: '#00AF50' }}>
                                <p className="mb-2 font-weight-bold"> Total Registrations </p>
                                <p className="mb-0"> {registrationsCount} </p>
                              </div>
                              :
                              <>
                                <div className="flex flex-col rounded-xl justify-center items-center py-1 px-3 flex-1 mr-2"
                                  style={{ backgroundColor: '#00AF50' }}>
                                  <p className="mb-2 font-weight-bold"> Paid </p>
                                  <p className="mb-0"> {paidCount} </p>
                                </div>
                                <div className="flex flex-col rounded-xl justify-center items-center py-1 px-3 flex-1"
                                  style={{ backgroundColor: '#EE8D7D' }}>
                                  <p className="mb-2 font-weight-bold"> Unpaid </p>
                                  <p className="mb-0"> {unpaidCount} </p>
                                </div>
                              </>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            {modalShow && (
              <EventDetailModal
                event={selectedEvent}
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            )}

            {modalShowApplicant && (
              <ApplicantModal
                data={selectedEvent}
                show={modalShowApplicant}
                onHide={() => setModalShowApplicant(false)}
              />
            )}

            {modalShowregistration && (
              <RegisteredModal
                // data={selectedEvent}
                data={registrations}
                show={modalShowregistration}
                onHide={() => setModalShowregistration(false)}
              />
            )}
          </div>
          {selectedEvent !== null && (
            <div className="col-12 col-sm-6 d-none d-md-block d-lg-block custom-card p-0">
              <div className="text-center">
                <div className="mt-2 fw-bolder">{selectedEvent.title}</div>
                <div className="text-muted fw-light">
                  <img
                    src={LocationImg}
                    className="me-1 inline"
                    alt="Location svg"
                  />
                  {selectedEvent.venue}
                </div>
                <div className="mt-4 p-1">
                  <span className="job-type p-2">
                    {selectedEvent.eventCategoryText}
                  </span>
                  <span className="fw-bold p-2">{selectedEvent.postedBy}</span>
                  <span className="ms-2 p-2 expire-text-box">
                    {selectedEvent.daysToExpiry}
                  </span>
                </div>
              </div>

              <div
                style={{
                  boxShadow: "0 0 25px 0 rgba(201,208,230,0.38)",
                }}
              >
                <hr className=" border-4 border-top "></hr>
              </div>

              <div style={{ fontSize: "0.8rem" }} className="p-2">
                <div>{parse(selectedEvent.description)}</div>

                <HorizontalLine />

                {/* <div>{parse(selectedEvent.topicsCovered)}</div> */}

                {/* <HorizontalLine />

                  <div>{parse(selectedEvent.companyDetails)}</div> */}

                <HorizontalLine />

                <div>
                  <div className="fw-bold">SPOC Person Details</div>
                  <div className="mt-1">
                    {"Posted By ( Name of the contact person ) :"}
                  </div>
                  <div className="mt-1 fw-bold text-muted">
                    {selectedEvent.postedBy}
                  </div>
                  <a
                    className="btn btn-primary ms-2"
                    href={`${process.env.REACT_APP_URL}/event/${selectedEvent.eventCategoryText}/${selectedEvent.id}/${selectedEvent.eventId}`}
                    role="button"
                    target="_blank"
                    rel="noreferrer noopener"
                    style={{
                      margin: "10px 0 10px 0 !important",
                      fontSize: "12px",
                      padding: "7px",
                    }}
                  >
                    Go To Link
                  </a>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
    // <>hello</>
  );
};

export default MyEvents;

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Function1 from "../assets/homepg/img/function-1.png";

import Cross from "../assets/Icons/cross.svg";

const DeleteUserModal = (props) => {
  const { show, handleClose } = props;

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
      className="delete-user-modal"
    >
      <div className="wrapper">
        <p className="col-12 justify-content-center py-6 flex items-center">
          Are you sure you want to delete this account ?
        </p>
        <div className="btn-container">
          <button className="btn btn-danger mr-4">Yes</button>
          <button className="btn" onClick={handleClose ? handleClose : () => {}}>No</button>
        </div>
        <div className="modal-close-icon">
          <img src={Cross} onClick={handleClose ? handleClose : () => {}} />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteUserModal;

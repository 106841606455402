import React from "react";
import { useState } from "react";

export default function DeleteAccount({ handleClose }) {
  const [step, setStep] = useState(0);
  return (
    <div className="delete-account-container row-span-full col-span-full">
      {step === 0 && (
        <section className="wrapper confirmation-wrapper">
          <p className="col-12 justify-content-center py-6 flex items-center">
            Click below to permanently delete your account from GlocalBodh
          </p>
          <div className="btn-container">
            <button className="btn" onClick={()=>setStep(1)} >Delete Account</button>
          </div>
        </section>
      )}
      {step === 1 && (
        <section className="wrapper delete-wrapper">
          <p className="col-12 justify-content-center py-6 flex items-center">
            Are you sure you want to delete this account ?
          </p>
          <div className="btn-container">
            <button className="btn mr-4 btn-yes" onClick={()=>setStep(2)}>Yes</button>
            <button
              className="btn btn-no"
              onClick={()=>setStep(0)}
            >
              No
            </button>
          </div>
        </section>
      )}
      {step === 2 && (
        <section className="wrapper success-wrapper">
          <p className="col-12 justify-content-center py-6 flex items-center">
            Account Deleted Successfully!
          </p>
        </section>
      )}
    </div>
  );
}

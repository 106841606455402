import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminService from "../../../services/admin.service";
import "bootstrap/dist/css/bootstrap.min.css";
import { setLoader, clearLoader } from "../../../store/actions/loader";
import ConfirmationModal from "../../../helpers/confirmationModal";
import { useInterval } from "../../../helpers/useInterval";
import { POLLING_INTERVAL } from "../../../constants/variables";
import fundingBanner from "./../../../assets/fundpg/fundingBanner.svg";
import example from "./../../../assets/fundpg/example.svg";
import ShareIcon from "../../../assets/Icons/share.svg";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ShareEventModal from "../../../helpers/shareEventModal";

const tempFundList = [
  {
    id: 0,
    name: "All",
    selected: true,
  },
  {
    id: 1,
    name: "Govt. Funds",
    selected: false,
  },
  {
    id: 2,
    name: "Foreign Funds",
    selected: false,
  },
  {
    id: 3,
    name: "CSR Funds",
    selected: false,
  },
];

// const fundings = [
//   {
//     id: 1,
//     designation: "Funding for Awareness",
//     issue: "Key Issues will go here",
//     location: "Location",
//     expiryLabel: "2 days left",
//     fundingCategory: "CSR Fund",
//   },
//   {
//     id: 2,
//     designation: "Funding for Awareness",
//     issue: "Key Issues will go here",
//     location: "Location",
//     expiryLabel: "2 days left",
//     fundingCategory: "CSR Fund",
//   },
//   {
//     id: 3,
//     designation: "Funding for Awareness",
//     issue: "Key Issues will go here",
//     location: "Location",
//     expiryLabel: "2 days left",
//     fundingCategory: "CSR Fund",
//   },
//   {
//     id: 4,
//     designation: "Funding for Awareness",
//     issue: "Key Issues will go here",
//     location: "Location",
//     expiryLabel: "2 days left",
//     fundingCategory: "CSR Fund",
//   },
// ];

const AllFundingUpdate = (props) => {
  const dispatch = useDispatch();
  const [fus, setFus] = useState([]);
  const [filteredFus, setFilteredFus] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [fundList, setFundList] = useState([...tempFundList]);
  // const [selectedFU, setSelectedFU] = useState({});
  // const [modalShowDelete, setModalShowDelete] = useState(false);
  const { id } = props.match.params;
  const history = useHistory();
  const [selectedFundType, setSelectedFundType] = useState(0);
  const { t } = useTranslation();
  const [shareModalActive, setShareModalActive] = useState(false)
  const [shareUrl, setShareUrl] = useState('')
  const query = props?.location?.search.split("=")[1];
  const { isLoggedIn } = useSelector((state) => state.auth);
  // useInterval(async () => {
  //   dispatch(setLoader());
  //   //console.log("Checking for data updates");
  //   await AdminService.fetchFundingUpdateByCategory(id, { dataType: 1 }).then(
  //     (res) => {
  //       dispatch(clearLoader());
  //       setFus(res.data.fundingUpdateBeans);
  //     }
  //   );
  // }, POLLING_INTERVAL);

  useEffect(() => {
    const fund = fundList.find((fund) => fund.id === parseInt(id));
    if (!fund) return;
    let temp = fundList.map((ev) => {
      if (ev.id === parseInt(id)) {
        return { ...ev, selected: true };
      } else {
        return { ...ev, selected: false };
      }
    });
    setFundList(temp);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(setLoader());
    AdminService.fetchFundingUpdateByCategory(0, 1)
      .then((res) => {
        dispatch(clearLoader());
        setFus(res.data.fundingUpdateBeans);
      })
      .catch(() => dispatch(clearLoader()));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps


  const handleCategoryChange = (id) => {
    let temp = fundList.map((ev) => {
      if (ev.id === id) {
        return { ...ev, selected: true };
      } else {
        return { ...ev, selected: false };
      }
    });
    setFundList(temp);
    const cat = getCategoryName(id);
    console.log(cat);
    if (cat === "All") {
      return setFilteredFus(fus);
    }
    let filtered = fus.filter((item) => item.fundingUpdateCategory === cat);
    setFilteredFus(filtered);
  };

  useEffect(() => {
    const selected = fundList.find((item) => item.selected === true);
    if (selected === undefined) return;
    // console.log(selected);
    const cat = getCategoryName(selected.id);
    // console.log(cat);
    if (cat === "All") {
      return setFilteredFus(fus);
    }
    let filtered = fus.filter((item) => item.fundingUpdateCategory === cat);
    setFilteredFus(filtered);
  }, [fundList, fus]);

  const getCategoryName = (id) => {
    if (id === 0) return "All";

    if (id === 1) {
      return "Govt. Funds";
    } else if (id === 2) {
      return "Foreign Funds";
    } else if (id === 3) {
      return "CSR Funds";
    }
  };

  const handleSelection = (e) => {
    console.log(e.target.id);
    setSelectedFundType(e.target.id);
  };

  const handleSubmit = () => {
    selectedFundType && history.push(`/user/create/fundingUpdate`);
  };

  const handleNavigate = (url, id) => {
    if (url === null || url === undefined || !url) {
      window.open(`${process.env.REACT_APP_URL}/fundingUpdate/ngo/${id}`);
    } else {
      window.open(url);
    }
    // window.open(url);
  };

  const getBackground = (totalLength, idx) => {
    let index = idx;
    const backgrounds = ["#51D294", "#C56DEE", "#6F7ADE", "#7DE94A", "#F6935A",];
    if (idx > backgrounds.length - 1) {
      index = index % backgrounds.length;
    }
    return backgrounds[index];
  };

  const handleShare = (funding) => {
    console.log("funding", funding);
    setShareModalActive(true);

    if (funding?.externalLink) {
      // navigator.clipboard.writeText(funding?.externalLink);
      // setShareUrl(funding?.externalLink);
      navigator.clipboard.writeText(
        `${process.env.REACT_APP_URL}/fundingUpdates/0?id=${funding.id}`
      );
      setShareUrl(`${process.env.REACT_APP_URL}/fundingUpdates/0?funding=${funding.id}`);
    } else {
      navigator.clipboard.writeText(
        `${process.env.REACT_APP_URL}/fundingUpdates/0?id=${funding.id}`
      );
      setShareUrl(`${process.env.REACT_APP_URL}/fundingUpdates/0?funding=${funding.id}`);
    }
  };

  //handle query params
  useEffect(() => {
    if (!fus) return
    if (!query) return
    if (fus.length === 0) return
    if (!isLoggedIn) {
      return document.getElementById("header-login-btn").click();
    }
    const funding = fus.find(item => item.id === query)
    if (!funding) return
    if(funding.externalLink){
      window.open(funding.externalLink)
    }
    history.push('/fundingUpdates/0')
  }, [query, fus, isLoggedIn])
  // console.log(filteredFus);
  return (
    <>
      <div className="pt-0 md:m-5 md:p-5 funding-page">
        <div className="grid grid-cols-12 p-5 bg-white funding-header d-none d-md-grid">
          <div className="grid items-center cols-span-4 justify-right">
            <div>
              <h1>
                Connect with Appropriate Implementation Partners through NGO
                search
              </h1>
              <p>
                Put up your requirement under Funding Update and select from most
                relevant Implementation Partners
              </p>
            </div>
          </div>
          <div className="cols-span-4">
            <img src={fundingBanner} alt="" />
          </div>
          <div className="grid justify-center items-center p-4 text-gray-400 shadow-md cols-span-4">
            <div>
              <h4 className="mb-4 text-center fw-normal">
                Looking for Implementation Partner?
              </h4>
              <div className="grid justify-center">
                <div className="grid gap-2 mb-4 w-80">
                  <div className="p-4 rounded border form-check ">
                    <input
                      className="p-3 m-0 form-check-input shadow-radio"
                      onChange={handleSelection}
                      name="select-event"
                      id="govt_funds"
                      type="radio"
                    />
                    <label
                      className="m-1 text-blue-600 form-check-label"
                      for="govt_funds"
                    >
                      <span className="ms-3">{t("Govt. Funds.1")}</span>
                    </label>
                  </div>
                  <div className="p-4 rounded border form-check ">
                    <input
                      className="p-3 m-0 form-check-input shadow-radio"
                      onChange={handleSelection}
                      name="select-event"
                      id="foreign_funds"
                      type="radio"
                    />
                    <label
                      className="m-1 text-blue-600 form-check-label"
                      for="foreign_funds"
                    >
                      <span className="ms-3">{t("Foreign Funds.1")}</span>
                    </label>
                  </div>
                  <div className="p-4 rounded border form-check ">
                    <input
                      className="p-3 m-0 form-check-input shadow-radio"
                      onChange={handleSelection}
                      name="select-event"
                      id="csr_funds"
                      type="radio"
                    />
                    <label
                      className="m-1 text-blue-600 form-check-label"
                      for="csr_funds"
                    >
                      <span className="ms-3">{t("CSR Funds.1")}</span>
                    </label>
                  </div>
                </div>
                <button onClick={handleSubmit} className="my-2 btn fs-4 w-80">
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center lg:bg-white all-events-categories md:px-24 md:mb-8">
          {fundList.map((event) => {
            return (
              <div
                key={event.id}
                className={`category-item ${event.selected ? "selected font-bold" : "font-semibold"
                  }`}
                onClick={() => handleCategoryChange(event.id)}
              >
                <p> {t(`${event.name}.1`)}</p>
              </div>
            );
          })}
        </div>

        <div className="grid md:grid-cols-2 md:px-24">
          {filteredFus?.map((funding, idx) => {
            return (
              <div
                key={funding.id}
                // onClick={() => handleSelectedfund(fund.fundId)}
                className={`flex p-4 mb-4 bg-white shadow-sm cursor-pointer lg:mr-4`}
                onClick={() => handleNavigate(funding.externalLink, funding.id)}
              // ${
              //   selectedfund.fundId === fund.fundId
              //     ? "lg:bg-gray-50"
              //     : "bg-white"
              // }`}
              >
                {/* <img src={example} alt="" className="pr-5 pb-5 w-25" /> */}
                <div
                  className="w-32 h-24 mr-4 d-flex items-center justify-center rounded-xl"
                  style={{
                    backgroundColor: getBackground(filteredFus.length, idx),
                  }}
                >
                  <div className="text-white fw-bold fs-1">
                    {funding.title.split("")[0]}
                  </div>
                </div>
                <div className="grid grid-cols-2 justify-between w-full">
                  <div className="event-card-content">
                    <p className="mb-2 text-blue-600 event-title fw-bold">
                      {funding?.title}
                    </p>
                    <p className="m-0">{funding.createdAtText}</p>
                    <p className="m-0">{funding.location}</p>
                    {/* <p className="m-0">{funding.createdByName}</p>
                  <p className="m-0">{funding.createdByContactNumber}</p> */}
                    <p className="m-0 text-sm text-gray-500">
                      {funding.expiryLabel}
                    </p>
                    {/* <small><u>Send me a fund like this</u></small> */}
                  </div>
                  <div className="grid justify-end justify-items-end">
                    <p className="text-blue-600 fw-bold">
                      {funding.fundingUpdateCategory}
                    </p>
                    <div className="grid items-end">
                      <img
                        src={ShareIcon}
                        style={{ width: "25px", height: "25px" }}
                        className="p-1 rounded-full hover:bg-gray-100"
                        onClick={e => { e.stopPropagation(); handleShare(funding) }}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <ShareEventModal
        show={shareModalActive}
        handleClose={() => setShareModalActive(false)}
        shareUrl={shareUrl}
        title='Share this funding update!'
        shareText="Checkout this job"
        isJob={true}
      />
    </>
  );
};

export default AllFundingUpdate;

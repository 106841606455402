export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const SET_LOADER = "SET_LOADER";
export const CLEAR_LOADER = "CLEAR_LOADER";

export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS";
export const GET_STATES_FAIL = "GET_STATES_FAIL";

export const GET_KYC_STATUS_SUCCESS = "GET_KYC_STATUS_SUCCESS";
export const GET_KYC_STATUS_FAIL = "GET_KYC_STATUS_FAIL";

export const GET_PAID_MODULES_SUCCESS = "GET_PAID_MODULES_SUCCESS";
export const GET_PAID_MODULES_FAIL = "GET_PAID_MODULES_FAIL";

export const GET_FU_DATA_SUCCESS = "GET_FU_DATA_SUCCESS";
export const GET_FU_DATA_FAIL = "GET_FU_DATA_FAIL";

import React from "react";

import {  Route } from "react-router-dom";
import PublicRoutes from "./public.routes";
import UserRoutes from "./user.routes";
import AdminRoutes from "./admin.routes";
import Switch from "react-bootstrap/esm/Switch";
import DeleteAccount from "../components/deleteAccount";

const Routes = () => {
  return (
    <Switch>
      <PublicRoutes />

      <UserRoutes />

      <AdminRoutes />
      
    </Switch>
  );
};
export default Routes;
